import React from 'react';

function PageNotFound() {
    return (
        <div>
            <h1>Sorry but this page does not exist!</h1>
        </div>
    )
}

export default PageNotFound;