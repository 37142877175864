import React from 'react';
import NavbarComponent from './components/navBar.js'
import Main from './components/main.js';

function App() {
  return(
    <div className ="backgroundImage">
      {/* <NavbarComponent /> */}
      <Main />
    </div>
    );
  }


export default App;
